const NAV_CONSTANT = () => {
  return {
    MENU: {
      SETTINGS: {
        TOOLTIP: "Settings",
        ITEMS: {
          preferences: "Edit Preferences",
          applications: "My Applications",
          connections: "My Connections",
          opentickets: "Open Tickets",
          createticket: "Raise an Issue",
        },
        SECTIONS: {
          support: "Support",
        },
      },
      NOTIFICATIONS: {
        TOOLTIP: "Notifications",
      },
      USER: {
        TOOLTIP: "Profile",
        ITEMS: {
          profile: "View Profile",
          password: "Change Password",
          plans: "Current Plan",
          invoices: "Due Invoices",
          logout: "Logout",
        },
        SECTIONS: {
          subscriptions: "Subscriptions",
          bookmarks: "Bookmarks",
        },
      },
    },
    MORE_TEXT: {
      APPLICATIONS: "See all applications",
      NOTIFICATIONS: "See all notifications",
    },
    SIDENAV: {
      IP: "IP Address",
      PLAN: "Plan",
      SANDBOX: "Sandbox",
      PRODUCTION: "Production",
    },
  };
};

const BASE_URL = "https://api.adiba.app/backoffice";
export const ENDPOINT_CONSTANT = {
  SUBSCRIPTION_EP: `${BASE_URL}/subscriptions/my`,
};

export const NAVIGATION_CONSTANT = NAV_CONSTANT();
