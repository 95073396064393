import { client } from "../auth.config";
import { BasicGet } from "./shared/library/fetchers/basic";
import { ENDPOINT_CONSTANT } from "../constants";

client
  .handleAuthentication()
  .then(async ({ shouldRender, state }) => {
    if (state?.finalRedirectUri) {
      location.href = state.finalRedirectUri;
    } else if (shouldRender) {
      const app = await import("./app");
      const {
        username,
        email,
        org_id,
        org_name,
        given_name,
        family_name,
        groups,
        sub,
        phone_number,
        job_title,
        email_verified,
        phone_verified,
        zoneinfo,
        language,
        locale,
        default_app,
        default_connection,
      } = await client.account();
      const org_email = "info@adiba.com";
      const token = await client.token();
      const {
        data: { applications, overdueInvoice, plan },
        status,
      } = await BasicGet(
        ENDPOINT_CONSTANT.SUBSCRIPTION_EP,
        token,
        org_id,
        default_connection
      );
      const group = Array.isArray(groups) ? groups.join(", ") : groups;
      const renderProfile = {
        applications: applications,
        activeapp: default_app,
        profile: {
          id: sub,
          user: { given_name, family_name, username, group, job_title },
          contact: { email, email_verified, phone_number, phone_verified },
          settings: {
            zoneinfo,
            language,
            default_connection,
            invoice: overdueInvoice,
            plan: plan.name,
          },
          meta: decipherLocale(locale),
          org: { org_id, org_name, org_email },
        },
      };
      app.render(renderProfile);
    }
  })
  .catch(async (err) => {
    console.error(err);
    client.logout();
  });

const decipherLocale = (locale) => {
  const currency = "Nigerian Naira (NGN)",
    language = "English (EN)",
    numberFormat = "###,###.00";
  return { currency, numberFormat, language };
};
