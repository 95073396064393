import { setupOidcClient } from "piral-oidc";

export const client = setupOidcClient({
    responseType: "code",
    clientId: process.env.CLIENT_ID,
    scopes: ['openid','groups','profile','email','phone'],
    identityProviderUri: process.env.AUTH_TOKEN_URL,
    postLogoutRedirectUri: "https://www.adiba.app",
    signInRedirectParams: {
      state: {
        finalRedirectUri: location.href
      }
    }
});