export const BasicGet = async (
  endpoint: string,
  token?: string,
  tenant?: string,
  shared?: string
) => {
  return fetch(endpoint, {
    headers: {
      Authorization: `Bearer ${token}`,
      "x-adb-tenant-ref": tenant,
      "x-adb-shared": shared
    },
  }).then((r) => r.json());
};
